/* General container styling */
.about-container {
  font-family: 'Roboto', sans-serif;
  color: #2c3e50;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 900px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Hero section styling */
.hero-section {
  text-align: center;
  background: linear-gradient(135deg, #ffa719, #1c1d1d);
  color: white;
  padding: 40px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-section p {
  font-size: 1.2rem;
  margin: 0;
}

/* Content section styling */
.content-section h2 {
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #34495e;
}

.content-section p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .content-section h2 {
    font-size: 1.5rem;
  }

  .content-section p {
    font-size: 0.95rem;
  }
}
