/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
  
  /* Main Container */
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh;
    background: linear-gradient(120deg, #1f1f1f, #333);
    color: #fff;
  }
  
  /* Header */
  .home-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .home-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0d92f4;
  }
  
  .home-header p {
    font-size: 1.1rem;
    opacity: 0.8;
    color: #726e6e;
  }
  
  /* Download Buttons */
  .download-buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .btn-download {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #0d92f4;
    color: #fff;
    text-decoration: none;
    border-radius: 25px;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .btn-download .icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  
  .btn-download:hover {
    background: #0b7cc3;
  }
  
  /* Search Form */
  .search-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    background: #222;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 0.9rem;
    margin-bottom: 8px;
    display: block;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #333;
    color: #fff;
    font-size: 1rem;
    outline: none;
    transition: background 0.3s ease;
  }
  
  .form-group input:focus {
    background: #444;
  }
  
  .btn-submit {
    padding: 10px 20px;
    background: #0d92f4;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .btn-submit:hover {
    background: #0b7cc3;
  }
  
  /* Results */
  .results {
    margin-top: 40px;
    text-align: center;
  }
  
  .results ul {
    list-style: none;
    margin-top: 20px;
  }
  
  .results li {
    background: #444;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .error {
    color: #f66;
  }
  
  /* Scroll Top Button */
  .scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #0d92f4;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .scroll-top:hover {
    background: #0b7cc3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .home-header h1 {
      font-size: 2rem;
    }
  
    .btn-download {
      font-size: 0.9rem;
    }
  
    .search-form {
      padding: 15px;
    }
  }
  
  .btn-view-stays {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .btn-view-stays:hover {
    background-color: #45a049;
  }
  