.home-details-container {
    width: 96%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .home-details-container h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  /* .home-image {
    width: 100%;
    max-width: 1200px;
  } */
  .image-carousel {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 20px;
  }
  
  .image-carousel img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .home-details-text {
    font-size: 1rem;
    color: #333;
  }
  
  .home-details-text p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .home-details-text strong {
    font-weight: bold;
  }
  
  .star-icon {
    color: #FFD700; /* Gold color for filled stars */
    font-size: 1.2rem;
    margin-right: 2px;
  }
  
  .star-icon.empty {
    color: #ddd; /* Grey color for empty stars */
  }
  
  .home-details-text {
    font-size: 1rem;
    color: #333;
  }
  
  .home-details-text p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .home-details-text strong {
    font-weight: bold;
  }

  /* Make carousel dots bigger and more visible */
.carousel .control-dots {
    bottom: 10px; /* Adjust the position of dots if necessary */
  }
  
  .carousel .control-dots .dot {
    background: #333; /* Darker color for better visibility */
    width: 18px; /* Increase width for larger dots */
    height: 18px; /* Increase height for larger dots */
    border-radius: 50%; /* Make them round */
    margin: 0 5px; /* Increase space between dots */
    transition: opacity 0.3s ease; /* Add a smooth transition effect */
  }
  
  .carousel .control-dots .dot.selected {
    background: #FFD700; /* Highlight selected dot with a gold color */
    transform: scale(1.2); /* Make the selected dot larger */
  }
  
  .inquiry-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #30a14a;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .inquiry-button:hover {
    background-color: #e09e41;
  }
  
/* Carousel main image styling */
.home-details-container .image-carousel .home-image {
  width: 100%;
  max-width: 600px; /* Maximum width for larger screens */
  max-height: 500px; /* Maximum height for larger screens */
  object-fit: cover; /* Ensures image is scaled proportionally and fills the frame */
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
}

/* Thumbnails styling */
.home-details-container .image-carousel .carousel .thumbs img {
  height: 80px; /* Consistent thumbnail height */
  width: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Uniform cropping */
  border-radius: 5px; /* Rounded thumbnails */
  margin: 2px; /* Add spacing between thumbnails */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .home-details-container .image-carousel .home-image {
    max-height: 400px; /* Reduce height for tablets */
  }

  .home-details-container .image-carousel .carousel .thumbs img {
    height: 70px; /* Slightly smaller thumbnails on tablets */
  }
}

@media (max-width: 576px) {
  .home-details-container .image-carousel .home-image {
    max-height: 300px; /* Reduce height for smaller mobile devices */
  }

  .home-details-container .image-carousel .carousel .thumbs img {
    height: 60px; /* Smaller thumbnails for mobile */
  }
}


.social-share {
  margin-top: 20px;
}

.share-button {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.share-button.facebook {
  background-color: #3b5998;
}

.share-button.twitter {
  background-color: #1da1f2;
}

.share-button.whatsapp {
  background-color: #25d366;
}

.share-button.linkedin {
  background-color: #0077b5;
}
