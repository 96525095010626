/* Footer Container */
.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex-wrap: wrap; /* Allow wrapping for responsive behavior */
}

/* Footer Links */
.footer-links {
  display: flex;
  gap: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #ff7f50;
}

/* Social Icons */
.footer-socials {
  display: flex;
  gap: 15px;
}

.footer-socials a {
  color: white;
  transition: color 0.3s;
}

.footer-socials a:hover {
  color: #ff7f50;
  transform: scale(1.1);
}

/* Footer Bottom */
.footer-bottom {
  margin-top: 10px;
  font-size: 14px;
  color: white; /* Ensure text color is white */
}

.footer-bottom-Bible {
  margin-top: 10px;
  font-size: 14px;
  color: white; /* Ensure text color is white */
  text-decoration: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column; /* Stack items vertically on smaller screens */
      text-align: center;
  }

  .footer-links {
      justify-content: center; /* Center footer links */
      margin-bottom: 10px;
  }

  .footer-socials {
      justify-content: center; /* Center social icons */
  }
}
