/* styles/Terms.css */

.terms-container {
    max-width: 800px; /* Limit the width for readability */
    margin: 20px auto; /* Center the content */
    padding: 20px; /* Add padding */
    background-color: #f9f9f9; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center;
}

h1 {
    text-align: center; /* Center the main heading */
    margin-bottom: 20px; /* Space below the heading */
    font-size: 2em; /* Size for the main heading */
}

.terms-section {
    margin-bottom: 20px; /* Space between sections */
}

h2 {
    font-size: 1.5em; /* Size for section headings */
    margin-bottom: 10px; /* Space below section headings */
    color: #333; /* Darker color for section headings */
}

p {
    font-size: 1em; /* Font size for paragraphs */
    line-height: 1.5; /* Space between lines for readability */
    color: #555; /* Slightly lighter text color for paragraphs */
}
