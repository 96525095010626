/* Cards Container */
.responsive-cards-container {
    display: flex;
    flex-wrap: wrap; /* Wrap cards to next row for responsiveness */
    justify-content: center; /* Center cards horizontally */
    gap: 20px; /* Space between cards */
    margin: 40px auto; /* Center container vertically */
    max-width: 1200px; /* Restrict maximum width */
    padding: 20px; /* Padding for outer spacing */
  }
  
  /* Individual Card */
  .responsive-card {
    flex: 1 1 calc(33.333% - 20px); /* Responsive layout */
    max-width: calc(33.333% - 20px); /* Three cards per row on larger screens */
    background: #ffffff; /* Card background */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    padding: 20px; /* Inner padding */
    text-align: center; /* Center content */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Hover interaction */
    cursor: pointer;
  }
  
  .responsive-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }
  
  /* Card Icon */
  .responsive-card-icon2 {
    font-size: 40px; /* Large icon size */
    color: #3399c5; /* Icon color */
    margin-bottom: 15px; /* Space below icon */
    transition: box-shadow 0.3s ease, color 0.3s ease; /* Smooth icon hover effect */
  }
  
  .responsive-card:hover .responsive-card-icon2 {
    color: #f69f00; /* Retain color on hover */
    box-shadow: 0 0 15px 4px rgba(246, 159, 0, 0.6); /* Glow effect on hover */
  }
  
  /* Card Content */
  .responsive-card-content h3 {
    font-size: 20px; /* Title font size */
    color: #333; /* Title color */
    margin-bottom: 10px; /* Space below title */
  }
  
  .responsive-card-content p {
    font-size: 16px; /* Description font size */
    color: #666; /* Description color */
    line-height: 1.5; /* Comfortable line spacing */
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .responsive-card {
      flex: 1 1 calc(50% - 20px); /* Two cards per row on medium screens */
      max-width: calc(50% - 20px); /* Restrict maximum width */
    }
  }
  
  @media (max-width: 576px) {
    .responsive-card {
      flex: 1 1 100%; /* Single card per row on small screens */
      max-width: 100%; /* Full width */
    }
  }
  