/* Container Styles */
.accommodation-tabs-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Tabs Navigation */
  .tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #f8f9fa;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .tabs button:hover {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .tabs .active-tab {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  /* Tab Content */
  .tab-content {
    margin-top: 20px;
  }
  
  /* List of Accommodation Cards */
  .accommodation-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  /* Individual Card */
  .accommodation-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    text-decoration: none;
    color: #333;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .accommodation-card:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  /* Card Image */
  .accommodation-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  /* Card Content */
  .accommodation-card h4 {
    font-size: 18px;
    margin: 15px;
    color: #333;
  }
  
  .accommodation-card p {
    font-size: 14px;
    margin: 0 15px 10px 15px;
    color: #666;
  }
  
  .accommodation-card p strong {
    color: #13457a;
  }
  
  /* No Accommodation Message */
  .no-accommodation-heading {
    font-size: 18px;
    text-align: center;
    color: #888;
    margin-top: 30px;
  }
  
  /* Error Message */
  .error-message {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .tabs button {
      font-size: 14px;
      padding: 8px 16px;
    }
  
    .accommodation-card h4 {
      font-size: 16px;
    }
  
    .accommodation-card p {
      font-size: 12px;
    }
  }
  