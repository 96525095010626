.not-found-container {
    text-align: center;
    padding: 50px;
  }
  
  .not-found-container h1 {
    font-size: 3rem;
    color: #333;
  }
  
  .not-found-container p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .back-home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .back-home-link:hover {
    background-color: #ff7f50;
  }
  