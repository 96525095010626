.private-bnb-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  .private-bnb-container-heading {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .no-bnb-heading {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .private-stays-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .stay-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
    transition: transform 0.3s;
    width: 300px; /* Set a fixed width */
  }
  
  .stay-card:hover {
    transform: scale(1.01); /* Scale effect on hover */
  }
  
  .stay-image {
    width: 100%;
    height: 200px; /* Set a fixed height */
    object-fit: cover; /* Cover the area without distortion */
  }
  
  h3 {
    margin: 10px;
    font-size: 1.6em;
  }

  h4 {
    margin: 10px;
    font-size: 24px;
  }
  
  p {
    margin: 5px 10px;
  }
  
  .create-new-home-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create-new-home-button {
    background-color: #28b34b;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .create-new-home-button:hover {
    background-color: #212521;
  }

  .stay-card span {
    color: #FFD700; /* Gold color for stars */
    font-size: 18px;
  }
  
  /* Styling for the select dropdown */
select {
  width: 200px; /* Full width for better responsiveness */
  font-size: 16px; /* Comfortable font size */
  color: #333; /* Text color */
  background-color: #ffffff; /* Background color */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
}

/* Hover effect for select */
select:hover {
  border-color: #007bff; /* Highlight border */
  box-shadow: 0 2px 10px rgba(0, 123, 255, 0.2); /* Add glow on hover */
}

/* Focused state for select */
select:focus {
  outline: none; /* Remove default outline */
  border-color: #0056b3; /* Darker blue for focus */
  box-shadow: 0 2px 10px rgba(0, 86, 179, 0.3); /* Enhanced glow for focus */
}

